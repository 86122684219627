// main.js
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import id from './locales/id.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import zh from './locales/zh.json';

Vue.use(VueI18n);

const messages = { en, id, es, pt, zh };

const userLang = navigator.language || navigator.userLanguage;
const langMap = { 'id': 'id', 'pt': 'pt', 'es': 'es', 'zh':"zh"};
const langPrefix = userLang.split('-')[0].toLowerCase();
const defaultLang = 'zh';
const locale = langMap[langPrefix] || defaultLang;

const i18n = new VueI18n({
  locale,
  messages,
});


Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
export { i18n }; // 导出 i18n 实例
