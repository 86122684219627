import Vue from 'vue'
import VueRouter from 'vue-router'
import { i18n } from '@/main'; 

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/face',
    name: 'face',
    component: () => import('../views/Face/Face.vue')
  },

  {
    path: '/faceresult',
    name: 'faceresult',
    component: () => import('../views/FaceResult/FaceResult.vue'),
  }, {
    path: '/faceFailed',
    name: 'faceFailed',
    component: () => import('../views/FaceFailed/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
  // // 如果当前路由是/faceresult 且目标路由是/faceFailed，则取消导航
  // if (from.path === '/faceresult' && to.path === '/faceFailed') {
  //   return;
  // }
  // next();
  router.beforeEach((to, from, next) => {
   
      const needsRedirect = Object.keys(from.query).some(
        key => !to.query[key]
      );
    if(to.query.language){
      i18n.locale = to.query.language;
    }

    // next()
    if (to.fullPath === from.fullPath) {
      return next();
    }
  
    // 如果没有查询参数并且路径不同，重定向并保留参数
    if (needsRedirect) {
   
      next({
        path: to.path,
        query: { ...from.query },
        replace: true,
      });
    } else {

      // 正常导航
      next();
    }
  });
  
export default router